import * as React from 'react'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import SetupSuccessSubnav from '../../components/setup_success_subnav'
import Container from '../../components/container'
import { StaticImage } from 'gatsby-plugin-image'
import Link from '../../components/link'
import AcademyBadge from '../../images/AcademyBadge.svg'
import DemoForm from '../../components/demo_form'

const DealerSuccessAndTrainingPage = () => (
  <Layout activeNav={true}>
    <Seo
      title='Dealer Success and Training'
      description='Our Integrations and Support teams will listen and learn about your business, and develop customized, scalable strategies to help you smoothly transition towards omnichannel commerce.'
    />
    <div className='flex flex-col justify-center relative bg-black text-white'>
      <StaticImage
        src='../../images/SS_DealerSuccess_Hero.jpg'
        placeholder='blurred'
        alt=''
        className='absolute inset-0'
      />
      <Container className='relative pb-4 pt-5 sm:pb-5 text-white'>
        <h1 className='font-semi-bold text-4vw'>
          Dealer Success & Training
        </h1>
        <p className='text-md sm:text-lg mb-0' style={{ maxWidth: '42em' }}>
          Ensure you’re set up for success from the start.
        </p>
      </Container>
    </div>
    <SetupSuccessSubnav />
    <div className='Section Section--stripe text-md leading-relaxed pb-0'>
      <Container>
        <div className='grid sm:grid-cols-2 sm:gap-4'>
          <div>
            <div className='flex mb-4'>
              <div className='size-fit font-semi-bold uppercase text-lg mt-1'>
                Step 1
              </div>
              <div className='border-l border-mid-gray mx-3'></div>
              <div className='flex-1'>
                <h3>
                  Get off to a strong start
                </h3>
                <p>
                  We’ll train your entire staff remotely on the customer journey, and sales agent and deal management tools. We’ll start with a management meeting to ensure alignment and to customize our training to your existing process. The idea is to get you up and running fast so that we can make our in person visit more meaningful.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='grid sm:grid-cols-2 sm:gap-4'>
          <div>
            <div className='flex mb-4'>
              <div className='size-fit font-semi-bold uppercase text-lg mt-1'>
                Step 2
              </div>
              <div className='border-l border-mid-gray mx-3'></div>
              <div className='flex-1'>
                <h3>
                  Review + improve
                </h3>
                <p>
                  Personalized follow-up comes standard with any Roadster product you purchase. We’ll check in frequently, post-launch, to make sure everything is going smoothly — including setting up your CRM, on-boarding new hires, modifying configurations and reviewing our reporting suite.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className='flex mb-4'>
              <div className='size-fit font-semi-bold uppercase text-lg mt-1'>
                Step 3
              </div>
              <div className='border-l border-mid-gray mx-3'></div>
              <div className='flex-1'>
                <img 
                  src={AcademyBadge}
                  alt=''
                  width={48}
                  height={48}
                  className='block'
                />
                <h3>
                  Roadster Academy
                </h3>
                <p>
                  Level up your training with Roadster Academy. Certification programs and resources are uniquely designed for individual dealership roles: From sales managers to BDC reps, everyone can become an Express Storefront expert in no time. A savvy sales team leads to more personalized experiences, greater sales efficiency and happier customers. That’s good for everyone.
                  {' '}
                  <Link
                    variant='underline'
                    to='/setup_and_success/roadster-academy'
                    children='Learn more'
                  />
                </p>
              </div>
            </div>
            <div className='flex'>
              <div className='size-fit font-semi-bold uppercase text-lg mt-1'>
                Step 4
              </div>
              <div className='border-l border-mid-gray mx-3'></div>
              <div className='flex-1'>
                <h3>
                  Receive ongoing support
                </h3>
                <p>
                  Our support never stalls. Our dealer support crew is available to offer you real, live help any day of the week.
                </p>
                <p>
                  Reach out to us at:
                  <br />
                  <Link
                    variant='underline'
                    href='mailto:dealersupport@roadster.com'
                    children='dealersupport@roadster.com'
                  />
                  {' '}
                  or
                  {' '}
                  <b>1-800-755-7355</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className='flex justify-center mt-4'>
        <StaticImage 
          src='../../images/support-crew.jpg'
          placeholder='blurred'
          alt=''
          width={920}
        />
      </div>
    </div>
    <div className='Section Section--dark'>
      <Container>
        <DemoForm invert={true} />
      </Container>
    </div>
  </Layout>
)

export default DealerSuccessAndTrainingPage
