import * as React from 'react'
import Subnav from './subnav'

const SetupSuccessSubnav = () => (
  <Subnav
    isSticky={true}
    links={[
      {
        to: '/setup_and_success/setup',
        text: 'Setup'
      },
      {
        to: '/setup_and_success/dealer_success_and_training',
        text: 'Dealer Success + Training'
      },
      {
        to: '/setup_and_success/roadster_academy',
        text: 'Roadster Academy'
      },
      {
        to: '/setup_and_success/dealer_support',
        text: 'Dealer Support'
      }
    ]}
  />
)

export default SetupSuccessSubnav
